<template>
  <v-container>

    <v-row>
      <v-col cols="12">

        <h2 class="font-weight-light pb-2 text-center text-md-left">Instructivos CBRLS</h2>
        <v-divider class="mb-5"></v-divider>

        <v-row>
          <v-col cols="12" md="6" v-for="video in lista_video" :key="video.id_publicacion_video">
            <v-fade-transition group>
              <video-tutoriales-item :video="video" :key="video.id_publicacion_video"></video-tutoriales-item>
            </v-fade-transition>
          </v-col>
        </v-row>

        <v-row v-if="cargandoVideos">
          <v-col cols="12" class="d-flex justify-center pt-6">
            <v-progress-circular indeterminate color="primary"/>
          </v-col>
        </v-row>


        <!--
        <div class="text-center">
          <v-btn color="primary" small @click="cargarMasVideos" v-if="!ultimo_video">
            Cargar mas videos
          </v-btn>
          <small v-if="ultimo_video">No hay más contenido por mostrar</small>
        </div>
        -->

      </v-col>
    </v-row>

  </v-container>

</template>

<script>

import videosEntity from "@/model/videos/videos.entity";
import VideoTutorialesItem from "@/views/index/videotutoriales/VideoTutorialesItem";

export default {
  name: "VidTutorialesView",
  components: {VideoTutorialesItem,},
  data() {
    return {
      cargandoVideos: true,
      lista_video: [],
      inicio: 0,
      ultimo_video: false,
      canLoadMoreVideos: false,
    }
  },
  created() {
    this.agregarVideos();
    window.addEventListener('scroll', () => {
      //si se ve el fondo(bottom) y no está cargando videos actualmente
      this.canLoadMoreVideos = this.bottomVisible() && !this.cargandoVideos
    })
  },
  watch: {
    canLoadMoreVideos(bottom) {
      if (bottom) {
        this.agregarVideos();
      }
    }
  },
  methods: {
    agregarVideos() {

      if (this.ultimo_video) {
        return;
      }

      this.cargandoVideos = true;
      videosEntity.getVideotutoriales(this.inicio).then(resp => {

        if (resp.length < 4) {
          this.ultimo_video = true;
        }

        resp.map(video => {
          this.lista_video.push(video);
        })

        this.inicio += resp.length;

        this.cargandoVideos = false;
      })
    },
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const borde = 650;
      const bottomOfPage = visible + scrollY + borde >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
  }
}
</script>

<style scoped>

</style>